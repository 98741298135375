import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../css/pages/updates.scss"


let faces = [
  "⋟﹏⋞",
  "⚆ᗝ⚆",
  "●﹏●",
  "⊙△⊙",
  "˚▱˚"
]

let faceTmp = faces[Math.floor(Math.random() * faces.length)];

const SecondPage = () => (
  <Layout>
    <SEO title="Updates" />
    <div className="updates">
      <div className="message">
        <h3 className="face">{faceTmp}</h3>
        <h3>Sorry this page is still being worked on!</h3>
      </div>
    </div>
  </Layout>
)

export default SecondPage
